var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panel',{staticClass:"font-weight-medium button"},[_c('v-expansion-panel-header',{staticClass:"py-0 pl-0 pr-5 rounded-r-0",class:_vm.setColorByState(_vm.studentInfo, 'lighten')},[_c('v-row',{staticClass:"pa-0 ma-0 d-flex align-center"},[_c('v-col',{staticClass:"pa-1 ma-0 d-flex align-center justify-center",class:_vm.setColorByState(_vm.studentInfo, 'darken'),attrs:{"lg":"1","md":"2","sm":"2"}},[_c('v-img',{staticClass:"ma-1 rounded-lg",attrs:{"aspect-ratio":"0.90","src":`${_vm.studentInfo.photo}`,"alt":`${_vm.studentInfo.first_name + ' ' + _vm.studentInfo.last_name}`,"max-height":"80","max-width":"65"}})],1),_c('v-col',{staticClass:"pa-0 my-0 mr-0 ml-sm-0 pl-3",attrs:{"lg":"9","md":"9","sm":"10"}},[_c('div',{staticClass:"d-flex flex-column align-start my-1 py-2"},[_c('span',{staticClass:"py-1 font-weight-bold"},[_vm._v(_vm._s(_vm.studentInfo.first_name + " " + _vm.studentInfo.last_name + " - " + _vm.studentInfo.code)+" ")]),_c('div',{staticClass:"d-flex mt-1"},[_c('span',{staticClass:"mr-3 font-weight-medium"},[_vm._v(_vm._s(_vm.studentInfo.grade + " " + _vm.studentInfo.section_group))]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.studentInfo.speciality))])])])])],1)],1),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"px-0 mb- mt-4 d-flex flex-lg-row flex-md-column flex-sm-column"},[(_vm.mode)?_c('v-col',{staticClass:"py-0 mb-2 d-flex flex-column",attrs:{"cols":"12"}},[(_vm.studentInfo.availableStudentClass != 0)?_c('span',[_vm._v(_vm._s(`${_vm.studentInfo.studentClassidSelected.length} seleccionados de ${_vm.studentInfo.availableStudentClass} disponibles`))]):_vm._e(),(
            _vm.studentInfo.studentClassidSelected.length == 0 &&
            _vm.studentInfo.touched &&
            _vm.studentInfo.availableStudentClass > 0
          )?_c('span',{staticClass:"red--text darken-1"},[_vm._v(_vm._s(`Debe de seleccionar al menos una ${_vm.label} para justificar`))]):_vm._e(),(
            _vm.studentInfo.studentClass.length == 0 &&
            _vm.studentInfo.availableStudentClass == 0
          )?_c('span',[_vm._v(_vm._s(`El estudiante no posee ${_vm.label} por justificar en el tiempo seleccionado`))]):_vm._e(),(
            _vm.studentInfo.availableStudentClass == 0 &&
            _vm.studentInfo.studentClass.length > 0
          )?_c('span',[_vm._v(" "+_vm._s(`Todas las ${_vm.label} han sido justificadas`))]):_vm._e()]):_c('v-col',{staticClass:"py-0 mb-2 d-flex flex-column",attrs:{"cols":"12"}},[_c('span',[_vm._v(" 0 clase(s) seleccionada de 1 ")]),_c('span',{staticClass:"red--text darken-1"},[_vm._v("Debe de seleccionar al menos una clase.")]),_c('span',[_vm._v("El estudiante no posee ninguna clase en el tiempo establecido")]),_c('span',[_vm._v("Todas las clases poseen un permiso ya establecido.")])]),_vm._t("default")],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }