<template>
  <v-card elevation="1" class="px-1 mx-1 mt-0">
    <v-card-title>{{ title }}</v-card-title>
    <v-data-table
      :headers="headersArray"
      :items="dataArray"
      :items-per-page="10"
      :loading="isLoadingStatus"
      :search="searchValue"
      :group-by="groupByValue"
      :loading-text="isLoadingStatus ? 'Cargando contenido...' : ''"
      class="text-body-1"
    >
      <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen">
            <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
            <v-icon v-else>mdi-chevron-down</v-icon></v-btn
          >
          <v-chip dense :color="`${colorTheme} lighten-4 mx-2`">
            <v-icon :color="colorTheme"> {{ groupIcon }}</v-icon>
          </v-chip>
          <span class="font-weight-bold text-subtitle-1 mt-1">
            {{ group }}
          </span>
        </td>
      </template>
      <template v-slot:[`item.full_name`]="{ item }" v-if="studentHasCodeAndFullName">
        <div class="d-flex align-center justify-end flex-sm-row-reverse">
          <div>
            <p class="font-weight-bold text-body-1 mb-0">
              <span>{{ item.code }}</span>
            </p>
            <p class="font-weight-bold text-body-1 mb-0">
              <span>{{ item.full_name }}</span>
            </p>
          </div>
          <v-avatar size="80" class="my-3 ml-3 mr-4">
            <img :src="item.photo" />
          </v-avatar>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ index }">
        <v-tooltip
          v-for="(item, methodIndex) in dataArray[index].actions"
          :key="methodIndex"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mx-1"
              medium
              :color="item.color"
              @click="item.method(index)"
              v-on="on"
              v-bind="attrs"
              >{{ item.icon }}</v-icon
            ></template>
          <span>{{ item.title }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "StudentsTable",
  props: {
    dataArray: {
      type: Array,
      required: true,
    },
    headersArray: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
    },
    isLoadingStatus: {
      type: Boolean,
    },
    searchValue: {
      type: String,
      default: null,
    },
    groupByValue: {
      type: String,
      default: null,
    },
    colorTheme: {
      type: String,
      default: "blue",
    },
    groupIcon: {
      type: String,
      default: "mdi-circle-medium",
    },
  },
  methods: {
    deleteMethod(index) {
      this.deleteOption(index);
    },
    studentHaveCodeFullnameAndPhoto(student) {
      if (student == undefined || typeof student === "number") {
        return false;
      }
      return "code" in student && "full_name" in student && "photo" in student;
    },
  },
  computed: {
    studentHasCodeAndFullName() {
      return this.dataArray.every(obj => "full_name" in obj && "code" in obj);
    }
  }
};
</script>