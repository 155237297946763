<template>
  <div>
    <v-stepper-step
      :complete="step > 3"
      :color="step > 3 ? 'success' : 'primary'"
      step="3"
    >
      <p class="font-weight-bold text-body-1 mb-0 mt-5 mt-xl-10">
        Selección de inasistencia o llegada tarde a justificar
      </p>
      <p class="mt-0 text-body-2 grey--text text--darken-1 mb-0">
        Seleccione las inasistencias o llegadas tarde que desea justificar.
      </p>
    </v-stepper-step>
    <v-stepper-content step="3">
      <v-row class="mb-2 ml-0">
        <v-col cols="12" v-if="studentClassInfo.length !== 0">
          <v-menu
            v-model="showColorimetry"
            max-width="400"
            :nudge-width="0"
            offset-x
            bottom
            :close-on-click="showColorimetry"
          >
            <template v-slot:activator="{ on, attrs }">
              <p class="py-0 my-0">
                ¿Tienes problemas para identificar el estado de los estudiantes
                con los colores? Haz click
                <a v-bind="attrs" v-on="on" class="font-weight-bold">aquí</a>
                para más información.
              </p>
            </template>
            <v-card>
              <v-card-title>Colorimetría</v-card-title>
              <v-card-text class="flex flex-column">
                <div class="flex flex-column">
                  <v-chip
                    color="amber lighten-3"
                    class="text-body-2 font-weight-bold mb-2"
                    >Aún no completado</v-chip
                  >
                  <p class="font-weight-medium mx-2 black--text">
                    Aún no se ha seleccionado ninguna inasistencia. (Disponibles
                    para seleccionar)
                  </p>
                </div>
                <div class="flex flex-column">
                  <v-chip
                    color="green lighten-3"
                    class="text-body-2 font-weight-bold mb-2"
                    >Completado</v-chip
                  >
                  <p class="font-weight-medium mx-2 black--text">
                    Todas o la menos una falta ha sido seleccionada para
                    justificar.
                  </p>
                </div>
                <div class="flex flex-column">
                  <v-chip
                    color="blue lighten-3"
                    class="text-body-2 font-weight-bold mb-2"
                    >Sin faltas disponibles para justificar</v-chip
                  >
                  <p class="font-weight-medium mx-2 black--text">
                    El estudiante no posee ninguna falta para seleccionar.
                  </p>
                </div>
                <div class="flex flex-column">
                  <v-chip
                    color="cyan lighten-3"
                    class="text-body-2 font-weight-bold mb-2"
                    >Faltas previamente justificadas</v-chip
                  >
                  <p class="font-weight-medium mx-2 black--text">
                    El estudiante posee faltas en el rango especificado pero
                    todas han sido justificadas. (No es posible justificar)
                  </p>
                </div>
                <div class="flex flex-column">
                  <v-chip
                    color="red lighten-3"
                    class="text-body-2 font-weight-bold mb-2"
                    >Sin justificaciones seleccionadas</v-chip
                  >
                  <p class="font-weight-medium mx-2 black--text">
                    No se ha seleccionado ninguna falta al estudiante. Se debe
                    de seleccionar al menos una.
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center align-center mt-10 mb-3"
          v-else
        >
          <p class="h6">
            No se han encontrado resultados con los datos seleccionados
          </p>
        </v-col>
        <v-col cols="12" class="pt-0" v-if="studentClassInfo.length !== 0">
          <v-tabs show-arrows>
            <v-tooltip
              top
              v-for="(value, index) in studentClassSelected.data"
              :key="index"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab class="pl-1">
                  <v-chip
                    v-show="value.touched"
                    class="px-2 py-0 mr-2"
                    :color="
                      value.studentsLeftToSelect == 0
                        ? 'green lighten-4'
                        : 'red lighten-4'
                    "
                    v-bind="attrs"
                    v-on="on"
                    >{{ value.studentsLeftToSelect }}</v-chip
                  >
                  {{ value.date }}
                </v-tab>
              </template>
              <span>
                {{
                  `Número de estudiantes sin ${justificationTypeLabel} justificadas`
                }}
              </span>
            </v-tooltip>
            <v-tab-item
              style="max-height: 450px"
              class="px-0o py-2 overflow-y-auto"
              v-for="(
                studentClassGroupedByDate, studentClassGroupedByDateindex
              ) in studentClassSelected.data"
              :key="studentClassGroupedByDateindex"
            >
              <v-expansion-panels>
                <StudentClassExpansionPanel
                  v-for="(
                    studentInformation, studentInfoindex
                  ) in studentClassGroupedByDate.info"
                  :key="studentInfoindex"
                  :label="justificationTypeLabel"
                  :studentInfo="studentInformation"
                >
                  <StudentClassJustificationPanel
                    v-for="(
                      studentClass, index
                    ) in studentInformation.studentClass"
                    :key="index"
                    :isJustified="
                      studentInformation.availableStudentClass > 0 &&
                      !studentClass.isJustified
                    "
                    :studentClassInfo="studentClass"
                    :studentClassSelectedArray="
                      studentInformation.studentClassidSelected
                    "
                    :saveStudent="saveStudentClass"
                    :studentClassGropedByDateIndex="
                      studentClassGroupedByDateindex
                    "
                    :studentInfoIndex="studentInfoindex"
                    :justificationLabel="justificationTypeLabel"
                    :attendanceType="secondStep.attendance_status_id"
                  />
                </StudentClassExpansionPanel>
              </v-expansion-panels>
            </v-tab-item>
          </v-tabs>
        </v-col>
        <v-col cols="12" class="d-flex justify-start">
          <v-btn
            color="white"
            class="mt-2 mr-2"
            @click="returnSecondStep()"
            :disabled="disableMode"
          >
            Regresar al segundo paso
            <v-icon right light>mdi-chevron-up</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            class="white--text mt-2"
            @click="completeStep()"
            :disabled="disableMode || studentClassInfo.length === 0"
          >
            Completar tercer paso
            <v-icon right light>mdi-chevron-down</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
// Components
import StudentClassJustificationPanel from "@/components/elements/justifications/studentClassSelectionComponents/StudentClassJustificationPanel";
import StudentClassExpansionPanel from "@/components/elements/justifications/studentClassSelectionComponents/StudentClassExpansionPanel";

import dayjs from "dayjs";

export default {
  name: "JustificationThirdStep",
  props: {
    step: {
      type: Number,
    },
    studentClassInfo: {
      type: Array,
      required: true,
      default: () => [],
    },
    returnStepMethod: {
      type: Function,
      required: true,
    },
    passStepMethod: {
      type: Function,
      required: true,
    },
    justificationTypeLabel: {
      type: String,
      required: true,
    },
    secondStep: {
      type: Object,
      required: true,
    },
    studentsSelected: {
      type: Array,
      required: true,
    },
    studentsFilterMode: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    StudentClassJustificationPanel,
    StudentClassExpansionPanel,
  },
  data() {
    return {
      showColorimetry: false,
      disableMode: false,
      studentClassSelected: {
        data: [],
        availableAmount: 0,
        isLoading: true,
      },
    };
  },
  methods: {
    // Transform and prepare data
    addStatusAndValidationInArray(studentClassArrayGroupedByDate) {
      const getStudentClasses = (studentClassArray) => {
        const availableStudentClass = [];
        studentClassArray.forEach((studentClass) => {
          if (!studentClass.isJustified)
            availableStudentClass.push(studentClass.idStudentClass);
        });
        return availableStudentClass;
      };

      const countAvailableStudentClass = (studentClassArray) => {
        let availableStudentClass = 0;
        studentClassArray.forEach((element) => {
          if (!element.isJustified) {
            availableStudentClass++;
          }
        });
        return availableStudentClass;
      };

      const studentDataTable = this.studentsSelected;
      const arrayResponse = [];
      studentClassArrayGroupedByDate.forEach((data) => {
        // Create a map by student uuid
        const studentResponseMap = new Map();
        data.info.forEach(({ studentId, ...rest }) => {
          studentResponseMap.set(studentId, { studentId, ...rest });
        });
        // Store students with available student class to select
        const studentWithStudentClass = [];
        const studentWithStudentClassJustified = [];
        // Complete students with missing students in data table
        const infoValue = [];
        studentDataTable.forEach((studentInfoFromDataTable) => {
          //let studentComplete = studentInfoFromDataTable;
          const studentFromResponse = studentResponseMap.get(
            studentInfoFromDataTable.uuid
          );
          const condition = studentFromResponse !== undefined;

          // Get only student class id
          const studentClasses = getStudentClasses(
            condition ? studentFromResponse.studentClass : []
          );

          const studentClassAvailableNumber = countAvailableStudentClass(
            condition ? studentFromResponse.studentClass : []
          );

          let extraField = {
            studentClassidSelected: this.studentsFilterMode
              ? studentClasses
              : [],
            studentClass: condition ? studentFromResponse.studentClass : [],
            touched: this.studentsFilterMode,
            availableStudentClass: studentClassAvailableNumber,
          };

          // Save available amount to stop or not in fourth step
          this.studentClassSelected.availableAmount =
            this.studentClassSelected.availableAmount +
            studentClassAvailableNumber;

          let studentComplete = { ...studentInfoFromDataTable, ...extraField };
          if (studentClassAvailableNumber > 0) {
            studentWithStudentClass.push(studentComplete);
            return;
          }
          if (studentComplete?.studentClass.length > 0) {
            studentWithStudentClassJustified.push(studentComplete);
            return;
          }
          infoValue.push(studentComplete);
        });
        const studentsOrderByAvailableStudentClass = studentWithStudentClass
          .concat(studentWithStudentClassJustified)
          .concat(infoValue);
        arrayResponse.push({
          date: data.date,
          info: studentsOrderByAvailableStudentClass,
          studentsLeftToSelect: 0,
          touched: this.studentsFilterMode,
        });
      });
      return arrayResponse;
    },
    completeArrayByDate(studentClassArrayGroupedByDate) {
      // get range of dates
      const dateRange = dayjs(this.secondStep.to_date).diff(
        this.secondStep.from_date,
        "day"
      );

      // Convert array of objects to map to easy lookup
      const studentClassByDateMap = new Map();
      studentClassArrayGroupedByDate.forEach(({ date, ...rest }) => {
        studentClassByDateMap.set(date, { date, ...rest });
      });

      // Search in date range to evalute if all date available exist
      const completeArrayWithMissingDate = [];
      Array.from({ length: dateRange + 1 }).forEach((_, i) => {
        const currentDate = dayjs(this.secondStep.from_date).add(i, "day");
        const dayOfWeek = currentDate.day();
        const dateFormatted = currentDate.format("YYYY-MM-DD");
        // Check if date is not saturday(6) or sunday (0)
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          let data = studentClassByDateMap.get(dateFormatted);

          // If date is not registered create default value
          if (!data) {
            data = { date: dateFormatted, info: [] };
          }
          completeArrayWithMissingDate.push(data);
        }
      });
      return completeArrayWithMissingDate;
    },
    transformResponse(response) {
      if (response.length == 0) {
        return (this.studentClassSelected = {
          data: [],
          availableAmount: 0,
          isLoading: true,
        });
      }
      // Complete array with dates missing (In date or range or date selected)
      const fixedStudentClassArrayByDate = this.completeArrayByDate(response);
      // Set default student class available (General for all dates)
      this.studentClassSelected.availableAmount = 0;
      // Add validations, status and show or not options to select
      const studentClassArrayFormmatted = this.addStatusAndValidationInArray(
        fixedStudentClassArrayByDate
      );

      this.studentClassSelected.data = [];
      this.studentClassSelected.data = studentClassArrayFormmatted;
    },
    saveStudentClass(
      value,
      studentClassByDateIndex,
      studentClassByStudentIndex
    ) {
      // Search for students array in date array
      const valuePath = this.studentClassSelected.data[studentClassByDateIndex];
      // Get student info in student grouped by date
      const studentClassSelectedObject =
        valuePath.info[studentClassByStudentIndex];
      const selectedStudentClass =
        studentClassSelectedObject.studentClassidSelected;
      // Search if uuid is selected or not
      const index = selectedStudentClass.indexOf(value);
      if (index == -1) {
        studentClassSelectedObject.studentClassidSelected.push(value);
        if (
          selectedStudentClass.length != 0 &&
          selectedStudentClass.length <= 1
        ) {
          valuePath.studentsLeftToSelect = valuePath.studentsLeftToSelect - 1;
        }
      } else {
        studentClassSelectedObject.studentClassidSelected.splice(index, 1);
        if (selectedStudentClass.length == 0) {
          valuePath.studentsLeftToSelect = valuePath.studentsLeftToSelect + 1;
        }
      }
      studentClassSelectedObject.touched = true;
    },
    returnSecondStep() {
      this.studentClassSelected = {
        data: [],
        availableAmount: 0,
        isLoading: true,
      };
      this.$v.studentClassSelected.$reset();
      this.returnStepMethod();
    },
    touchAllStudents() {
      this.$v.studentClassSelected.$touch();
      this.studentClassSelected.data.forEach((studentClassByDate, index) => {
        studentClassByDate.info.forEach((studentInfo, infoIndex) => {
          this.$set(this.studentClassSelected.data[index].info, infoIndex, {
            ...this.studentClassSelected.data[index].info[infoIndex],
            touched: true,
          });
        });
        this.$set(this.studentClassSelected.data, index, {
          ...this.studentClassSelected.data[index],
          touched: true,
        });
      });
    },
    makeStudentClassIdArray() {
      let studentClassId = [];
      this.studentClassSelected.data.forEach((studentClassByDate) => {
        studentClassByDate.info.forEach((studentClassByStudent) => {
          studentClassId = studentClassId.concat(
            studentClassByStudent.studentClassidSelected
          );
        });
      });
      return studentClassId;
    },
    completeStep() {
      // Execute validations
      this.touchAllStudents();
      if (this.$v.studentClassSelected.$invalid) {
        return this.fireToast({
          title: "Revisa que todos los campos estén completados",
          icon: "warning",
        });
      }
      this.passStepMethod(this.makeStudentClassIdArray());
    },
    resetValues() {
      this.studentClassSelected = {
        data: [],
        availableAmount: 0,
        isLoading: true,
      };
      this.$v.studentClassSelected.$reset();
    },
  },
  validations: {
    studentClassSelected: {
      checkStudentClassSelected() {
        const verificationArray = [];
        let selectedStudentClass = 0;
        this.studentClassSelected.data.forEach((studentClassByDate) => {
          // Reset counter
          studentClassByDate.studentsLeftToSelect = 0;
          studentClassByDate.info.forEach((studentClassByStudent) => {
            if (
              studentClassByStudent.studentClassidSelected.length == 0 &&
              studentClassByStudent.availableStudentClass != 0
            ) {
              // Register to a student was found without selection
              verificationArray.push(false);
              // Add number of students without selection
              studentClassByDate.studentsLeftToSelect++;
              return;
            }
            selectedStudentClass =
              selectedStudentClass +
              studentClassByStudent.studentClassidSelected.length;
          });
          // Show chips
        });
        return verificationArray.length === 0 && selectedStudentClass != 0;
      },
    },
  },
  watch: {
    studentClassInfo(newValue, oldValue) {
      this.transformResponse(newValue);
    },
  },
};
</script>