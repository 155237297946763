<template>
  <v-menu
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :nudge-right="40"
    v-model="showTimer"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formatTime"
        v-bind="attrs"
        v-on="on"
        :label="labelText"
        readonly
        filled
        rounded
        :prepend-icon="prependIcon"
        :prepend-inner-icon="prependInnerIcon"
        @blur="executeValidation()"
        @input="executeValidation()"
        @change="executeValidation()"
        :error-messages="errorMessages"
        :disabled="isDisabled"
      >
      </v-text-field>
    </template>
    <v-time-picker
      ampm-in-title
      :format="clockFormat"
      :min="minTime"
      :max="maxTime"
      :disabled="isDisabled"
      v-model="optionSelected"
      @click:minute="executeReturnMethod()"
    ></v-time-picker>
  </v-menu>
</template>

<script>
import dayjs from "dayjs";
import "dayjs/locale/es";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("es");
export default {
  name: "dropdownMenu",
  props: {
    preSelectedOption: {
      type: String,
      default: null,
    },
    validationMethod: {
      type: Object,
      default: null,
    },
    maxTime: {
      type: String,
      default: "",
    },
    minTime: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    clockFormat: {
      type: String,
      default: "24hr",
    },
    inputMethod: {
      type: Function,
      required: true,
    },
    errorMessages: {
      type: Array,
      default: [],
    },
    labelText: {
      type: String,
      required: true,
    },
    prependIcon: {
      type: String,
      default: "",
    },
    prependInnerIcon: {
      type: String,
      default: ''
    },
  },
  data: () => ({
    optionSelected: null,
    showTimer: false,
  }),
  methods: {
    executeValidation() {
      if (this.validationMethod != null) {
        this.validationMethod.$touch();
      }
    },
    executeReturnMethod() {
      const optionStructure = this.optionSelected.split(":");
      let timeSelected = this.optionSelected;
      if (optionStructure.length === 2) {
        timeSelected = timeSelected + ":00";
      }
      this.inputMethod(timeSelected);
      this.showTimer = false;
    },
    resetValidation() {
      this.optionSelected = this.preSelectedOption;
    },
  },
  mounted() {
    this.resetValidation();
  },
  computed: {
    formatTime() {
      if (this.preSelectedOption != null && this.preSelectedOption != "") {
        const timeSplited = this.preSelectedOption.split(":");
        return dayjs()
          .set("hours", timeSplited[0])
          .set("minutes", timeSplited[1])
          .format("hh:mm a");
      }
      if (this.optionSelected != "" && this.optionSelected != null) {
        const timeSplited = this.optionSelected.split(":");
        return dayjs()
          .set("hours", timeSplited[0])
          .set("minutes", timeSplited[1])
          .format("hh:mm a");
      }
      return "Selecciona una hora";
    },
  },
  watch: {
    preSelectedOption() {
      if (this.preSelectedOption != null) {
        this.optionSelected = this.preSelectedOption;
      }
    }
  }
};
</script>