<template>
  <div>
    <div class="white rounded-lg pa-5 py-md-8 px-md-10">
      <template>
        <!-- begin::Page header and title -->
        <div class="card-title">
          <h3 class="card-label">
            Justificaciones
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podrá justificar inasistencias o llegas tarde a clases
              para los estudiante, tanto individual como grupalmente.</span
            >
          </h3>
        </div>
        <!-- end::Page header and title -->

        <!-- begin::Content-->
        <v-row class="mt-5">
          <v-col cols="12" class="px-0 px-sm-3 pl-lg-0 mt-1">
            <v-stepper
              class="mt-n10 pl-0 ml-n6 mr-n5 ml-sm-3"
              vertical
              v-model="step"
              flat
            >
              <JustificationFirstStep
                ref="firstStepComponent"
                :step="step"
                :studentsObject="studentsSelect"
                :grades="gradesSelect"
                :passStepMethod="passSecondStep"
              />
              <JustificationSecondStep
                ref="secondStepComponent"
                :step="step"
                :minClassTime="minClassTime"
                :maxClassTime="maxClassTime"
                :passStepMethod="passThirdStep"
                :returnStepMethod="backFirstStep"
                :disableMode="disableMode"
              />
              <JustificationThirdStep
                ref="thirdStepComponent"
                :step="step"
                :studentClassInfo="studentClassInfo"
                :returnStepMethod="backSecondStep"
                :passStepMethod="passFourthStep"
                :justificationTypeLabel="justificationTypeLabel"
                :secondStep="secondStep"
                :studentsSelected="firstStep"
                :studentsFilterMode="studentsFilterMode"
              />
              <JustificationFourthStep
                ref="fourthStepComponent"
                :step="step"
                :disableMode="disableMode"
                :returnStepMethod="backThirdStep"
                :passStepMethod="completeJustification"
              />
            </v-stepper>
          </v-col>
        </v-row>
      </template>
    </div>
    <!-- Inicia:: Botón flotante para el historial de justificaciones -->
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-on="on"
          v-bind="attrs"
          fixed
          fab
          bottom
          right
          color="primary"
          :class="`floatingTranstition ${floatingPosition}`"
          @click="openJustificationRecord()"
        >
          <v-icon>mdi-clipboard-text-clock</v-icon>
        </v-btn>
      </template>
      <span>Historial de justificaciones</span>
    </v-tooltip>
    <!-- Finaliza:: Botón flotante para el historial de justificaciones -->

    <!-- Modal para el historial de justificaciones -->
    <JustificationModal
      ref="justificationModal"
      titleLabel="Historial de justificaciones"
      colorTheme="green"
      :studentClassInformation="justificationInfo.data"
      :isLoading="justificationInfo.isLoading"
      @reloadData="loadJustifications"
      :forCoordinations="true"
      :faultType="2"
    />
  </div>
</template>

<script>
// Repositories
import studentRepository from "@/repositories/studentRepository";
import classTimeRepository from "@/repositories/classTimeRepository";
import gradeRepository from "@/repositories/gradeRepository";
import studentClassRepository from "@/repositories/studentClassRepository";
import studentJustificationsRepository from "@/repositories/studentJustificationsRepository";
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
// Components
import JustificationFirstStep from "@/components/elements/justifications/steps/JustificationFirstStep";
import JustificationSecondStep from "@/components/elements/justifications/steps/JustificationSecondStep";
import JustificationThirdStep from "@/components/elements/justifications/steps/JustificationThirdStep";
import JustificationFourthStep from "@/components/elements/justifications/steps/JustificationFourthStep";

import JustificationModal from "@/components/modals/justification_permission/JustificationModal.vue";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import dayjs from 'dayjs';

export default {
  name: "Justifications",
  data() {
    return {
      step: 1,
      disableMode: false,
      studentsFilterMode: false,
      // Initial data
      gradesSelect: {
        isLoading: true,
        data: [],
      },
      studentsSelect: {
        isLoading: true,
        data: [],
      },
      minClassTime: "",
      maxClassTime: "",
      // First step
      firstStep: [],
      // Second step
      secondStep: {
        attendance_status_id: 2,
        from_date: null,
        to_date: null,
        start_time: null,
        end_time: null,
      },
      // Third step
      thirdStep: [],
      // Student class array response
      studentClassInfo: [],

      // Floating bottom css
      floatingPosition: "",
      
      justificationInfo: {
        data: [],
        isLoading: false,
      },
    };
  },
  components: {
    JustificationFirstStep,
    JustificationSecondStep,
    JustificationThirdStep,
    JustificationFourthStep,
    JustificationModal
  },
  methods: {
    // Methods for load inital data
    loadGrades() {
      gradeRepository
        .getAllGrades()
        .then(({ data }) => {
          this.gradesSelect.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No se han podido cargar los grados",
          });
        })
        .finally(() => {
          // Add default option
          this.gradesSelect.data.unshift({
            id: null,
            name: "Selecciona un grado",
          });
          this.gradesSelect.isLoading = false;
        });
    },
    loadStudents() {
      this.studentsSelect.isLoading = true;
      studentRepository
        .getAllStudents()
        .then(({ data }) => {
          this.studentsSelect.data = data;
        })
        .catch(() =>
          this.fireToast({
            icon: "error",
            title: "No se han podido cargar los estudiantes",
          })
        )
        .finally(() => (this.studentsSelect.isLoading = false));
    },
    loadMinAndMaxClassTime() {
      classTimeRepository
        .getMinAndMaxClassTime()
        .then(({ data }) => {
          this.minClassTime = data.data.minClassTime;
          this.maxClassTime = data.data.maxClassTime;
        })
        .catch((e) => {
          this.fireToast({
            icon: "error",
            title: "Ocurrió un error al obtener datos",
          });
        });
    },
    // Methods for first step
    passSecondStep(studentsArray, filterMode) {
      this.firstStep = studentsArray;
      this.studentsFilterMode = filterMode;
      this.step = 2;
    },
    // Methods for second step
    passThirdStep(info) {
      if (info.start_time == "" || this.end_time == "") {
        info.start_time = this.minClassTime;
        info.end_time = this.maxClassTime;
      }
      this.secondStep = info;
      this.searchStudentClass();
    },
    // Methods for third step
    searchStudentClass() {
      this.disableMode = true;
      // Payload
      const payloadForSearchStudentClass = {
        codes: this.makeStudentsArray(),
        startDate: this.secondStep.from_date,
        endDate: this.secondStep.to_date,
        startTime: this.secondStep.start_time,
        endTime: this.secondStep.end_time,
        attendanceStatusId: this.secondStep.attendance_status_id,
      };

      // Search information
      if (this.secondStep.attendance_status_id === 5) {
        studentClassRepository
          .postStudentClassAndInfoForLateArrivalToInstitution(
            payloadForSearchStudentClass
          )
          .then(({ data }) => {
            this.studentClassInfo = data.data;
            this.step = 3;
          })
          .catch((e) => {
            this.fireToast({
              icon: "error",
              title: `No se han podido obtener las ${this.justificationTypeLabel} para los estudiantes seleccionados`,
            });
          })
          .finally(() => {
            this.disableMode = false;
          });
      } else {
        studentClassRepository
          .postStudentClassAndInfoForAbsentOrLateArrival(
            payloadForSearchStudentClass
          )
          .then(({ data }) => {
            this.studentClassInfo = data.data;
            this.step = 3;
          })
          .catch((e) => {
            this.fireToast({
              icon: "error",
              title: `No se han podido obtener las ${this.justificationTypeLabel} para los estudiantes seleccionados`,
            });
          })
          .finally(() => {
            this.disableMode = false;
          });
      }
    },
    makeStudentsArray() {
      return this.firstStep.map((element) => {
        return element.uuid;
      });
    },
    passFourthStep(studentClassArray) {
      this.thirdStep = studentClassArray;
      this.step = 4;
    },
    // Methods for fourth step
    completeJustification(fourthStep) {
      this.disableMode = true;
      const request = { ...fourthStep, ...this.secondStep };
      const { attendance_status_id, end_time, start_time, ...rest } = request;
      const completeRequest = {
        studentClassIds: JSON.stringify(this.thirdStep),
        user_id: this.currentUserPersonalInfo.id_user,
        ...rest,
      };
      const payload = this.objectToFormData(completeRequest);
      // Start post
      studentJustificationsRepository
        .createJustification(payload)
        .then(() => {
          this.fireToast({
            icon: "success",
            title: "Justificación guardada correctamente",
          });
          this.resetForm();
          this.step = 1;
        })
        .catch((e) => {
          console.log(e)
          this.fireToast({
            icon: "error",
            title: "Ocurrió un problema al guardar la justificación",
          });
        })
        .finally(() => {
          this.disableMode = false;
        });
    },
    resetForm() {
      this.$refs.firstStepComponent.resetValues();
      this.$refs.secondStepComponent.resetValues();
      this.$refs.thirdStepComponent.resetValues();
      this.$refs.fourthStepComponent.resetValues();
    },
    // Returns methods
    backFirstStep() {
      this.step = 1;
    },
    backSecondStep() {
      this.step = 2;
      this.studentClass = {
        data: [],
        availableAmount: 0,
        isLoading: false,
      };
    },
    backThirdStep() {
      this.step = 3;
    },
    // Cambia el css para la posición del botón flotante
    changePosition() {
      const yPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (yPosition > 265) {
        this.floatingPosition = "moveToUpButton";
        return;
      } 
      this.floatingPosition = "";
    },
    openJustificationRecord() {
      const currentDate = dayjs().format("YYYY-MM-DD");
      this.$refs.justificationModal.toggleModal();
      this.loadJustifications({ startDate: currentDate, endDate: currentDate });
    },
    loadJustifications(dates) {
      this.justificationInfo.data = [];
      this.justificationInfo.isLoading = true;
      studentJustificationsRepository
      .getJustificationsByRangeDate(dates.startDate, dates.endDate, 2)
      .then(({ data }) => {
        if (data?.message) {
            this.justificationInfo.data = [];
          } else {
            this.justificationInfo.data = data.data;
          }
      })
      .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.justificationInfo.isLoading = false;
        });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Justificaciones", route: "justifications" },
    ]);
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Codigos");
    this.loadGrades();
    this.loadStudents();
    this.loadMinAndMaxClassTime();
    // Verifica el desplazamiento para mover el botón flotante
    window.addEventListener("scroll", this.changePosition);
  },
  unmounted() {
    window.removeEventListener("scroll", this.changePosition);
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    justificationTypeLabel() {
      if (this.secondStep.attendance_status_id === 5) {
        return "llegada tarde a la institución";
      }
      if (this.secondStep.attendance_status_id === 3) {
        return "llegada tarde a clases";
      }
      return "inasistencia";
    },
    floatingButtonPosition() {
      return ''
    }
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>

<style scoped>

.floatingTranstition {
  transition: all 0.4s ease-out 1 !important;
  transition: 200ms linear 200ms !important;
}

.moveToUpButton {
  bottom: 85px !important;
}
</style>
