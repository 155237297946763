<template>
  <div>
    <v-stepper-step
      :complete="step > 1"
      :color="step > 1 ? 'success' : 'primary'"
      step="1"
    >
      <p class="font-weight-bold text-body-1 mb-0 mt-5 mt-xl-10">
        Selección de estudiantes
      </p>
      <p class="mt-0 text-body-2 grey--text text--darken-1 mb-0">
        Seleccione los estudiantes que se les justificarán inasistencias o
        llegadas tarde. Puede seleccionar un estudiante por código o toda una
        sección académica o grupo técnico.
      </p>
    </v-stepper-step>
    <v-stepper-content class="mt-0 pt-0" step="1">
      <v-card color="elevation-0">
        <v-row class="mt-3 mb-2 ml-0">
          <v-col cols="12" class="mb-0 pb-0 pt-5">
            <!-- begin::Change filter options mode -->
            <v-col cols="12 pb-0 px-0 mb-0">
              <v-switch
                class="pl-0 ml-0 my-2 py-0"
                v-model="studentsFilterMode"
                @change="changeFilterMode()"
                :label="`¿Desea justificar estudiantes individualmente? ${
                  !studentsFilterMode ? 'Si' : 'No'
                }`"
                :disabled="disableMode"
              ></v-switch>
            </v-col>
            <!-- end::Change filter options mode -->

            <v-col cols="12" class="mt-0 pt-0 px-0 mb-5">
              <!-- begin::Filter options (per students) -->
              <v-scroll-x-transition
                :leave-absolute="true"
                :hide-on-leave="true"
              >
                <v-col
                  cols="12"
                  class="my-0 py-0 px-0"
                  v-if="!studentsFilterMode"
                >
                  <v-autocomplete
                    :label="
                      studentsObject.isLoading
                        ? 'Cargando estudiantes...'
                        : 'Código del estudiante'
                    "
                    :loading="studentsObject.isLoading"
                    filled
                    rounded
                    open-on-clear
                    clearable
                    eager
                    hide-details
                    return-object
                    :items="students"
                    item-text="code"
                    item-value="code"
                    v-model="studentTemporalElection"
                    :filter="customFilter"
                    @change="addStudent(studentTemporalElection)"
                  >
                    <!-- begin::selected student-->
                    <!---->
                    <template v-slot:selection="{ item, index }">
                      <v-chip
                        color="primary"
                        small
                        v-if="index === 0 && studentTemporalElection"
                      >
                        <span>{{
                          item.first_name + " " + item.last_name
                        }}</span>
                      </v-chip>
                    </template>
                    <!-- end::selected student-->
                    <!-- begin::select students by item option-->
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-row>
                          <v-col cols="1">
                            <v-avatar>
                              <img
                                :src="`${item.photo}`"
                                :alt="`${
                                  item.first_name + ' ' + item.last_name
                                }`"
                              />
                            </v-avatar>
                          </v-col>
                          <v-col cols="11">
                            <v-list-item-title>
                              <span class="font-weight-medium">
                                {{ item.code }}
                              </span>
                              -
                              <span class="text-uppercase font-weight-medium">
                                {{ item.first_name + " " + item.last_name }}
                              </span>
                              <v-chip
                                x-small
                                class="ma-2"
                                :color="`${
                                  item.status == 'Activo'
                                    ? 'success'
                                    : 'red darken-1'
                                }`"
                                outlined
                              >
                                <span class="font-weight-bold">{{
                                  item.status
                                }}</span>
                              </v-chip>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <span class="ml-2"
                                >Grado
                                <strong class="ml-1">{{ item.grade }}</strong>
                              </span>
                              <span class="ml-2">
                                Sección
                                <strong class="ml-1">{{
                                  item.section_group
                                }}</strong>
                              </span>
                              <span class="ml-2">
                                Especialidad
                                <strong class="ml-1">{{
                                  item.speciality
                                }}</strong>
                              </span>
                            </v-list-item-subtitle>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </template>
                    <!-- end::select students by item option-->
                  </v-autocomplete>
                </v-col>
              </v-scroll-x-transition>
              <!-- end::Filter options (per students) -->

              <!-- begin::Filter options (per group) -->
              <v-scroll-x-transition
                :leave-absolute="true"
                :hide-on-leave="true"
              >
                <v-row
                  class="d-flex justify-start flex-sm-column flex-md-row flex-lg-row"
                  v-if="studentsFilterMode"
                >
                  <v-col cols="12" class="mb-0 pb-0">
                    <v-select
                      :items="grades.data"
                      item-text="name"
                      item-value="id"
                      :label="
                        grades.isLoading
                          ? 'Cargando grados disponibles'
                          : 'Grado'
                      "
                      :loading="grades.isLoading"
                      filled
                      rounded
                      :disabled="disableMode"
                      v-model="gradeElection"
                      @change="searchForGrade()"
                    />
                  </v-col>
                  <!-- begin::Specifc options -->
                  <v-col lg="6" md="6" sm="12" class="mt-0 py-0">
                    <v-select
                      :items="academicSectionSelect.data"
                      item-text="grade_section_group_formatted"
                      item-value="id"
                      :label="
                        academicSectionSelect.isLoading
                          ? 'Cargando secciones disponibles'
                          : 'Sección académica'
                      "
                      :loading="academicSectionSelect.isLoading"
                      filled
                      rounded
                      :disabled="gradeElection == null || disableMode"
                      v-model="academicElection"
                      @change="fillTable(1)"
                    />
                  </v-col>
                  <v-col lg="6" md="6" sm="12" class="mt-0 py-0">
                    <v-select
                      :items="technicalNameAndGroupsSelect.data"
                      item-text="grade_speciality_group_formatted"
                      item-value="id"
                      :label="
                        technicalNameAndGroupsSelect.isLoading
                          ? 'Cargando grupos técnicos disponibles'
                          : 'Grupo técnico'
                      "
                      :loading="technicalNameAndGroupsSelect.isLoading"
                      filled
                      rounded
                      :disabled="gradeElection == null || disableMode"
                      v-model="technicalElection"
                      @change="fillTable(2)"
                    />
                  </v-col>
                  <!-- end::Specifc options -->
                </v-row>
              </v-scroll-x-transition>
              <!-- end::Filter options (per group) -->
            </v-col>
          </v-col>
        </v-row>
        <!-- end::Filter options -->
        <!-- begin::Visualizer -->
        <StudentsTable
          title="Estudiantes seleccionados"
          :isLoadingStatus="studentsDataTable.isLoading"
          :dataArray="studentsDataTable.data"
          :headersArray="studentsDataTable.headers"
        />
        <!-- end::Visualizer -->
        <v-btn
          color="primary"
          class="white--text mt-3"
          @click="passSecondStep()"
          :disabled="disableMode"
        >
          Completar primer paso
          <v-icon right light>mdi-chevron-down</v-icon>
        </v-btn>
      </v-card>
    </v-stepper-content>
  </div>
</template>

<script>
// Repositories
import academicGroupRepository from "@/repositories/academicGroupRepository";
import technicalGroupRepository from "@/repositories/technicalGroupRepository";
import studentRepository from "@/repositories/studentRepository";

// Components
import StudentsTable from "@/components/elements/justifications/StudentsTable";

export default {
  name: "JustificationFirstStep",
  components: {
    StudentsTable,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    studentsObject: {
      type: Object,
      required: true,
    },
    passStepMethod: {
      type: Function,
      required: true,
    },
    grades: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      studentTemporalElection: null,
      disableMode: false,
      // Headers and table
      studentsDataTable: {
        data: [],
        headers: [
          {
            text: "Nombres",
            align: "start",
            sortable: true,
            value: "first_name",
          },
          {
            text: "Apellidos",
            align: "start",
            sortable: true,
            value: "last_name",
          },
          {
            text: "Código",
            align: "start",
            sortable: true,
            value: "code",
          },
          {
            text: "Especialidad",
            align: "start",
            sortable: true,
            value: "speciality",
          },
          {
            text: "Grado",
            align: "start",
            sortable: true,
            value: "grade",
          },
          {
            text: "Sección",
            align: "start",
            sortable: true,
            value: "section_group",
          },
          {
            text: "Acciones",
            align: "center",
            sortable: false,
            value: "actions",
          },
        ],
        isLoading: false,
      },
      students: [],
      // Election variables
      gradeElection: null,
      academicElection: null,
      technicalElection: null,
      studentsFilterMode: false,
      // Select's data
      academicSectionSelect: {
        data: [],
        isLoading: false,
      },
      technicalNameAndGroupsSelect: {
        data: [],
        isLoading: false,
      },
    };
  },
  methods: {
    customFilter(item, queryText) {
      const searchText = queryText
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      const codeMatch = item.code.toString().includes(searchText);
      const firstName = item.first_name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(searchText);
      const lastName = item.last_name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(searchText);
      return codeMatch || firstName || lastName;
    },
    changeFilterMode() {
      this.studentsDataTable.data = [];
      this.gradeElection = null;
      this.academicElection = null;
      this.technicalElection = null;
      this.studentTemporalElection = null;
    },
    addStudent(student) {
      this.studentTemporalElection = student;
      // Check if students is already in table
      if (this.studentsDataTable.data.includes(student)) {
        this.fireToast({
          icon: "warning",
          title: "El estudiante ya está seleccionado",
        });
      } else if (student != null) {
        this.studentsDataTable.data.push(student);
      }
      // Reset value in autocomplete
      this.$nextTick(() => {
        this.studentTemporalElection = null;
      });
    },
    // Complete first step
    passSecondStep() {
      if (this.studentsDataTable.data.length <= 0) {
        return this.fireToast({
          icon: "warning",
          title: "Debes de seleccionar al menos un estudiante para continuar",
        });
      }
      this.passStepMethod(this.studentsDataTable.data, this.studentsFilterMode);
    },
    // Search data
    searchForGrade() {
      this.disableMode = true;
      this.academicSectionSelect.isLoading = true;
      this.technicalNameAndGroupsSelect.isLoading = true;
      // Reset data to don't merge
      this.academicSectionSelect.data = [];
      this.technicalNameAndGroupsSelect.data = [];
      // Search for both to choose
      this.loadSpecificGradeGroups(this.gradeElection);
      this.loadSpecificTechnicalGroup(this.gradeElection);
    },
    loadSpecificGradeGroups(GradeID) {
      if (GradeID != null) {
        academicGroupRepository
          .getAcademicGroupsByGrade(GradeID)
          .then(({ data }) => {
            this.academicSectionSelect.data = data;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "No se han podido cargar las secciones académicas",
            });
          })
          .finally(() => {
            this.disableMode = false;
            this.academicSectionSelect.isLoading = false;
            // Add reset option
            this.academicSectionSelect.data.unshift({
              grade_section_group_formatted: "Selecciona una sección académica",
              id: null,
            });
          });
      }
    },
    loadSpecificTechnicalGroup(GradeID) {
      if (GradeID != null) {
        technicalGroupRepository
          .getTechnicalGroupsByGrade(GradeID)
          .then(({ data }) => {
            this.technicalNameAndGroupsSelect.data = data;
          })
          .catch(() => {
            this.fireToast({
              icon: "error",
              title: "No se han podido cargar los grupos técnicos",
            });
          })
          .finally(() => {
            this.disableMode = false;
            this.technicalNameAndGroupsSelect.isLoading = false;
            this.technicalNameAndGroupsSelect.data.unshift({
              grade_speciality_group_formatted: "Selecciona una especialidad",
              id: null,
            });
          });
      }
    },
    // Search for students and reset values of other select
    fillTable(modeElection) {
      // For search a grade
      if (modeElection === 1) {
        this.studentsDataTable.data = [];
        this.studentsDataTable.isLoading = true;
        this.disableMode = true;
        studentRepository
          .getStudentByAcademicGroup(this.academicElection)
          .then(({ data }) => {
            const studentWithMethods = data.map((element) => {
              let student = element;
              student.actions = [
                {
                  title: "Remover estudiante de la selección",
                  icon: "mdi-delete",
                  color: "red",
                  method: this.deleteItem,
                },
              ];
              return student;
            });
            this.studentsDataTable.data = studentWithMethods;
          })
          .catch((e) => {
            this.fireToast({
              icon: "error",
              title: "No se han podido cargar los estudiantes",
            });
          })
          .finally(() => {
            this.studentsDataTable.isLoading = false;
            this.disableMode = false;
          });
      }
      // For search a technical group
      if (modeElection === 2) {
        this.studentsDataTable.data = [];
        this.studentsDataTable.isLoading = true;
        this.disableMode = true;
        studentRepository
          .getStudentByTechnicalGroup(this.technicalElection)
          .then(({ data }) => {
            const studentWithMethods = data.map((element) => {
              let student = element;
              student.actions = [
                {
                  title: "Remover estudiante de la selección",
                  icon: "mdi-delete",
                  color: "red",
                  method: this.deleteItem,
                },
              ];
              return student;
            });
            this.studentsDataTable.data = studentWithMethods;
          })
          .catch((e) => {
            this.fireToast({
              icon: "error",
              title: "No se han podido cargar los estudiantes",
            });
          })
          .finally(() => {
            this.studentsDataTable.isLoading = false;
            this.disableMode = false;
          });
      }
    },
    // Delete students in data table values
    deleteItem(itemIndex) {
      this.studentsDataTable.data.splice(itemIndex, 1);
    },
    // Reset values
    resetValues() {
      this.studentsFilterMode = false;
      this.studentTemporalElection = null;
      this.studentsDataTable.data = [];
      this.academicSectionSelect.data = [];
      this.technicalNameAndGroupsSelect.data = [];
      this.gradeElection = null;
    },
  },
  watch: {
    studentsObject: {
      handler() {
        this.students = this.studentsObject?.data.map((studentInfo) => {
          let student = studentInfo;
          student.actions = [
            {
              title: "Remover estudiante de la selección",
              icon: "mdi-delete",
              color: "red",
              method: this.deleteItem,
            },
          ];
          return student;
        });
      },
      deep: true,
    },
  },
};
</script>