<template>
  <v-col lg="6" md="12" sm="12" class="px-2 mx-0">
    <v-card class="mx-1 pb-0" elevation="2" outlined>
      <!-- Begin:: Interactive option card (When is nos justified)-->
      <div
        v-if="isJustified"
        class="d-flex flex-no-wrap justify-start"
        :class="`${studentClassIsSelected ? 'white' : 'blue lighten-4'}`"
      >
        <div
          :class="`d-flex justify-center align-center pl-2 blue ${
            studentClassIsSelected ? 'darken-1' : 'darken-3'
          } rounded rounded-r-0`"
        >
          <v-checkbox
            class="mx-1"
            dark
            :input-value="studentSelectionCheckBox"
            @change="executeSaveMethod()"
          ></v-checkbox>
        </div>
        <div v-if="attendanceType !== 5">
          <v-card-title class="pb-5">{{
            studentClassInfo.subject
          }}</v-card-title>
          <v-card-subtitle class="body-1">{{
            formatScheduleTime(studentClassInfo.schedule)
          }}</v-card-subtitle>
        </div>
        <div v-else>
          <v-card-title class="pb-5">
            Llegada tarde a la institución
          </v-card-title>
          <v-card-subtitle class="body-1 d-flex flex-column">
            <span>
              <span class="font-weight-medium">Asignado por:</span>
              {{ `${studentClassInfo.userName} ${studentClassInfo.userLastName}` }}
            </span>
            <span>
              <span class="font-weight-medium">Hora de asignación:</span>
              {{ studentClassInfo.arrivalTime }}
            </span>
          </v-card-subtitle>
        </div>
      </div>
      <!--End::Interactive option card (When is nos justified)-->
      <!--Begin:: Information card (When is already justified)-->
      <div v-else class="d-flex flex-no-wrap justify-start green lighten-5">
        <v-tooltip top color="green darken-1">
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex justify-center align-center green darken-1 px-0">
              <span class="mx-3" v-on="on" v-bind="attrs">
                <v-icon medium color="white">check</v-icon>
              </span>
            </div>
          </template>
          <span class="white--text">{{
            `${capitalizeFirstLetter(justificationLabel)} justificada`
          }}</span>
        </v-tooltip>
        <div v-if="attendanceType !== 5">
          <v-card-title class="pb-5">{{
            studentClassInfo.subject
          }}</v-card-title>
          <v-card-subtitle class="body-1">{{
            formatScheduleTime(studentClassInfo.schedule)
          }}</v-card-subtitle>
        </div>
        <div v-else>
          <v-card-title class="pb-5">
            Llegada tarde a la institución
          </v-card-title>
          <v-card-subtitle class="d-flex">
            <span class="mr-3 font-weight-medium">Asignado por:</span>
            <span class="font-weight-medium">Hora de asignación:</span>
          </v-card-subtitle>
        </div>
      </div>
      <!--End:: Information card (When is already justified)-->
    </v-card>
  </v-col>
</template>

<script>
import dayjs from "dayjs";

export default {
  name: "StudentClassJustificationPanel",
  props: {
    isJustified: {
      type: Boolean,
      default: true,
    },
    studentClassInfo: {
      type: Object,
      required: true,
    },
    studentClassSelectedArray: {
      type: Array,
      required: true,
    },
    saveStudent: {
      type: Function,
      required: true,
    },
    studentClassGropedByDateIndex: {
      type: Number,
      required: true,
    },
    studentInfoIndex: {
      type: Number,
      required: true,
    },
    justificationLabel: {
      type: String,
      required: true,
    },
    attendanceType: {
      type: Number,
      required: true,
    },
  },
  methods: {
    executeSaveMethod() {
      this.saveStudent(
        this.studentClassInfo.idStudentClass,
        this.studentClassGropedByDateIndex,
        this.studentInfoIndex
      );
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    formatTime(value) {
      const timeSplited = value.split(":");
      return dayjs()
        .set("hour", timeSplited[0])
        .set("minutes", timeSplited[1])
        .format("hh:mm a");
    },
    formatScheduleTime(value) {
      const timeSplited = value.split("-");
      return (
        this.formatTime(timeSplited[0]) +
        " - " +
        this.formatTime(timeSplited[1])
      );
    },
  },
  computed: {
    studentClassIsSelected() {
      return (
        this.studentClassSelectedArray.indexOf(
          this.studentClassInfo.idStudentClass
        ) == -1
      );
    },
    studentSelectionCheckBox() {
      return (
        this.studentClassSelectedArray.indexOf(
          this.studentClassInfo.idStudentClass
        ) != -1
      );
    },
    asignedBy() {
      return `${this.studentClassInfo.userName} ${this.studentClassInfo.userLastName} - ${studentClassInfo.userType}`;
    },
    asignedAt() {
      return this.studentClassInfo.arrivalTime !== null
        ? this.studentClassInfo.arrivalTime
        : "No definido";
    },
  },
};
</script>

