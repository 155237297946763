<template>
  <div>
    <v-stepper-step :color="false ? 'success' : 'primary'" step="4">
      <p class="font-weight-bold text-body-1 mb-0 mt-5 mt-xl-10">
        Detalle de la justificación
      </p>
      <p class="mt-0 text-body-2 grey--text text--darken-1 mb-0">
        Complete los datos para justificar la inasistencia o llegada tarde a los
        estudiantes.
      </p>
    </v-stepper-step>
    <v-stepper-content step="4">
      <v-row class="ml-0 mb-2">
        <v-col cols="12" class="pt-5 my-0 py-0">
          <v-text-field
            label="Motivo de la justificación"
            filled
            rounded
            class="my-0 py-0"
            v-model="fourthStep.motive"
            :error-messages="motiveErrors"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-select
            :items="justificationTypes"
            item-text="text"
            item-value="value"
            label="Tipo de justificación"
            filled
            rounded
            v-model="fourthStep.type"
          ></v-select>
        </v-col>
        <v-col cols="6" class="py-0">
          <v-file-input
            label="Constancia"
            truncate-length="25"
            type="file"
            filled
            rounded
            v-model="fourthStep.file"
          >
          </v-file-input>
        </v-col>
        <v-col cols="12" class="d-flex justify-start">
          <v-btn
            color="white"
            class="mr-2"
            @click="returnThirdStep()"
            :disabled="disableMode"
          >
            Regresar al tercer paso
            <v-icon right light>mdi-chevron-up</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            class="white--text"
            @click="completeStep()"
            :disabled="disableMode"
            :loading="disableMode"
          >
            Completar justificación
            <v-icon right light>mdi-check-bold</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "JustificationFourthStep",
  props: {
    step: {
      type: Number,
      required: true,
    },
    disableMode: {
      type: Boolean,
      required: true,
    },
    returnStepMethod: {
      type: Function,
      required: true,
    },
    passStepMethod: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      fourthStep: {
        motive: null,
        type: "Normal",
        file: null,
      },
      justificationTypes: [
        {
          text: "Justificación",
          value: "Normal",
        },
        {
          text: "Justificación ITR",
          value: "ITR",
        },
      ],
    };
  },
  methods: {
    completeStep() {
      this.$v.fourthStep.$touch();
      if (this.$v.fourthStep.$invalid) {
        this.fireToast({
          title: "Verifica los campos antes de continuar",
          icon: "warning",
        });
      }
      this.passStepMethod(this.fourthStep);
    },
    returnThirdStep() {
      this.fourthStep = {
        motive: null,
        type: "Normal",
        file: null,
      };
      this.$v.fourthStep.$reset();
      this.returnStepMethod();
    },
    resetValues() {
      this.fourthStep = {
        motive: null,
        type: "Normal",
        file: null,
      };
      this.$v.fourthStep.$reset();
    },
  },
  computed: {
    motiveErrors() {
      const errors = [];
      if (!this.$v.fourthStep.motive.$dirty) return errors;
      if (!this.$v.fourthStep.motive.required)
        errors.push("Es necesario que ingrese el motivo de la justificación.");
      if (!this.$v.fourthStep.motive.minLength)
        errors.push("El motivo debe de contener al menos 5 caracteres.");
      return errors;
    },
  },
  validations: {
    fourthStep: {
      motive: {
        required,
        minLength: minLength(5),
      },
    },
  },
};
</script>
