<template>
  <div>
    <v-stepper-step
      :complete="step > 2"
      :color="step > 2 ? 'success' : 'primary'"
      step="2"
    >
      <p class="font-weight-bold text-body-1 mb-0 mt-5 mt-xl-10">
        Selección tiempo a justificar
      </p>
      <p class="mt-0 text-body-2 grey--text text--darken-1 mb-0">
        Personalice la búsqueda de los bloques que desea justificar a los
        estudiantes usando fechas y horas en específico.
      </p>
    </v-stepper-step>
    <v-stepper-content step="2">
      <v-row class="mt-3 mb-2 ml-1">
        <v-col lg="6" sm="12" class="pb-0 pl-10">
          <v-select
            :items="attendaceStatusSelect"
            item-text="text"
            item-value="value"
            label="Falta a justificar"
            filled
            rounded
            @change="deployTimers()"
            v-model="secondStep.attendance_status_id"
          />
        </v-col>
        <v-col lg="6" sm="12" class="pb-0 pl-10">
          <p class="mb-0">Selecciona el método de búsqueda</p>
          <v-switch
            class="mt-1"
            v-model="modeTimeLapse"
            @change="changeModeTimeLapse()"
            :label="modeTimeLapse ? 'Rango de fechas' : 'Día individual'"
            :disabled="disableMode"
          ></v-switch>
        </v-col>
        <v-row class="d-flex flex-row align-start justify-start mt-0 pt-0 mx-5">
          <v-card
            color="elevation-0"
            class="col-12 mr-15 pb-4 pt-4 px-5 mb-5"
            outlined
          >
            <!-- begin::Individual date selection -->
            <v-scroll-x-transition :leave-absolute="true" :hide-on-leave="true">
              <v-row v-if="!modeTimeLapse" class="pt-0 mt-5">
                <v-col class="py-0 my-0" lg="6" sm="12">
                  <DateDropdownMenu
                    :allowDates="allowDates"
                    :isDisabled="disableMode"
                    :inputMethod="getFromDate"
                    :validationMethod="$v.secondStep.from_date"
                    :errorMessages="startDateErrors"
                    :preSelectedOption="secondStep.from_date"
                    prependInnerIcon="mdi-calendar"
                    labelText="Fecha de justificación"
                    ref="justificationDate"
                  />
                </v-col>
                <v-col lg="6" sm="0" class="py-0 transparent"></v-col>
                <v-slide-y-transition leave-absolute mode="in-out">
                  <v-col class="py-0" lg="6" sm="12" v-if="showTimersField">
                    <TimeDropdownMenu
                      :preSelectedOption="secondStep.start_time"
                      :validationMethod="$v.secondStep.start_time"
                      :maxTime="maxClassTime"
                      :minTime="minClassTime"
                      :isDisabled="disableMode"
                      prependInnerIcon="mdi-clock-time-eight-outline"
                      :inputMethod="getStartTime"
                      :errorMessages="startTimeErrors"
                      labelText="Hora de inicio"
                      ref="startTime"
                    />
                  </v-col>
                </v-slide-y-transition>
                <v-slide-y-transition leave-absolute mode="in-out">
                  <v-col class="py-0" lg="6" sm="12" v-if="showTimersField">
                    <TimeDropdownMenu
                      :preSelectedOption="secondStep.end_time"
                      :validationMethod="$v.secondStep.end_time"
                      :maxTime="maxClassTime"
                      :minTime="minClassTime"
                      :isDisabled="disableMode"
                      prependInnerIcon="mdi-clock-time-eight-outline"
                      :inputMethod="getEndTime"
                      :errorMessages="endTimeErrors"
                      labelText="Hora de fin"
                      ref="endTime"
                    />
                  </v-col>
                </v-slide-y-transition>
              </v-row>
            </v-scroll-x-transition>
            <!-- end::Individual date selection -->

            <!-- begin::range date selection -->
            <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
              <v-row v-if="modeTimeLapse" class="pt-0 mt-5">
                <v-col class="py-0" lg="6" md="6" sm="12">
                  <DateDropdownMenu
                    :allowDates="allowDates"
                    :isDisabled="disableMode"
                    :inputMethod="getFromDate"
                    :errorMessages="startDateErrors"
                    :validationMethod="$v.secondStep.from_date"
                    :preSelectedOption="secondStep.from_date"
                    prependInnerIcon="mdi-calendar"
                    labelText="Fecha de inicio"
                    ref="startDate"
                  />
                </v-col>
                <v-col class="py-0" lg="6" md="6" sm="12">
                  <DateDropdownMenu
                    :allowDates="allowDates"
                    :isDisabled="disableMode"
                    :inputMethod="getToDate"
                    :errorMessages="endDateErrors"
                    :validationMethod="$v.secondStep.to_date"
                    :preSelectedOption="secondStep.to_date"
                    prependInnerIcon="mdi-calendar"
                    labelText="Fecha de fin"
                    ref="endDate"
                  />
                </v-col>
              </v-row>
            </v-scroll-x-transition>
          </v-card>
          <!-- end::range date selection -->
        </v-row>
        <v-col cols="12" class="d-flex justify-start">
          <v-btn color="white" class="mt-2 mr-2" @click="returnFirstStep()">
            Regresar al primer paso
            <v-icon right light>mdi-chevron-up</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            class="white--text mt-2"
            @click="completeStep()"
            :disabled="disableMode"
            :loading="disableMode"
          >
            Completar segundo paso
            <v-icon right light>mdi-chevron-down</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-stepper-content>
  </div>
</template>

<script>
// Components
import DateDropdownMenu from "@/components/elements/justifications/DateDropdownMenu";
import TimeDropdownMenu from "@/components/elements/justifications/TimeDropdownMenu";
import { required } from "vuelidate/lib/validators";
import dayjs from "dayjs";

export default {
  name: "JustificationSecondStep",
  components: {
    DateDropdownMenu,
    TimeDropdownMenu,
  },
  data() {
    return {
      // Configs
      modeTimeLapse: false,
      showTimersField: true,
      // Initial options
      attendaceStatusSelect: [
        {
          text: "Inasistencia a clases",
          value: 2,
        },
        {
          text: "Llegada tarde a clases",
          value: 3,
        },
        {
          text: "Llegada tarde a la institución",
          value: 5,
        },
      ],
      // Values
      secondStep: {
        attendance_status_id: 2,
        from_date: dayjs().format("YYYY-MM-DD"),
        to_date: dayjs().format("YYYY-MM-DD"),
        start_time: "",
        end_time: "",
      },
    };
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    minClassTime: {
      type: String,
    },
    maxClassTime: {
      type: String,
    },
    returnStepMethod: {
      type: Function,
      required: true,
    },
    passStepMethod: {
      type: Function,
      required: true,
    },
    disableMode: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    // Config methods
    allowDates(val) {
      const dateNumber = dayjs(val).day();
      return (
        dateNumber != 0 &&
        dateNumber != 6 &&
        dayjs(val).startOf("day") <= dayjs().startOf("day")
      );
    },
    changeModeTimeLapse() {
      this.secondStep.attendance_status_id = 2;
      this.secondStep.from_date = dayjs().format("YYYY-MM-DD");
      this.secondStep.to_date = dayjs().format("YYYY-MM-DD");
      this.$v.secondStep.$reset();
    },
    // Getter methods
    getFromDate(date) {
      this.secondStep.from_date = date;
      this.secondStep.to_date = this.modeTimeLapse ? this.secondStep.to_date : date;
      this.$v.secondStep.from_date.$touch();
    },
    getToDate(date) {
      this.secondStep.to_date = date;
      this.$v.secondStep.to_date.$touch();
    },
    getStartTime(time) {
      this.secondStep.start_time = time;
      this.$v.secondStep.start_time.$touch();
    },
    getEndTime(time) {
      this.secondStep.end_time = time;
      this.$v.secondStep.end_time.$touch();
    },
    returnFirstStep() {
      this.secondStep = {
        attendance_status_id: 2,
        from_date: dayjs().format("YYYY-MM-DD"),
        to_date: dayjs().format("YYYY-MM-DD"),
        start_time: "",
        end_time: "",
      };
      this.showTimersField = false;
      this.modeTimeLapse = false;
      this.$v.secondStep.$reset();
      this.returnStepMethod();
    },
    completeStep() {
      this.$v.secondStep.$touch();
      if (this.$v.secondStep.$invalid) {
        return this.fireToast({
          title: "Verificar los campos antes de continuar",
          icon: "warning",
        });
      }
      this.passStepMethod(this.secondStep);
    },
    // Validations methods
    formatTime(value) {
      const timeSplited = value.split(":");
      return dayjs()
        .set("hour", timeSplited[0])
        .set("minutes", timeSplited[1])
        .format("hh:mm a");
    },
    deployTimers() {
      this.showTimersField = this.secondStep.attendance_status_id !== 5;
    },
    resetValues() {
      this.secondStep = {
        attendance_status_id: 2,
        from_date: dayjs().format("YYYY-MM-DD"),
        to_date: dayjs().format("YYYY-MM-DD"),
        start_time: "",
        end_time: "",
      };
      this.showTimersField = false;
      this.modeTimeLapse = false;
      this.$v.secondStep.$reset();
    },
  },
  validations: {
    secondStep: {
      from_date: {
        required,
        maxDate(val) {
          return new Date(val) <= new Date();
        },
      },
      to_date: {
        maxDate(val) {
          return new Date(val) <= new Date();
        },
        checkDate(val) {
          return (
            !this.modeTimeLapse ||
            new Date(val) >= new Date(this.secondStep.from_date)
          );
        },
      },
      start_time: {
        checkMinTime(val) {
          const splitedstartTime = val.split(":");
          const minPosibleTimeSplited = this.minClassTime.split(":");
          const startTimeInADate = dayjs()
            .startOf("day")
            .set("hour", splitedstartTime[0])
            .set("minute", splitedstartTime[1]);
          // Set min time admited for start time 7:00
          const minTime = dayjs()
            .startOf("date")
            .set("hour", minPosibleTimeSplited[0])
            .set("minutes", minPosibleTimeSplited[1]);
          return (
            this.modeTimeLapse ||
            !this.showTimersField ||
            startTimeInADate >= minTime
          );
        },
        checkMaxTime(val) {
          const splitedstartTime = val.split(":");
          const maxPosibleTimeSplited = this.maxClassTime.split(":");
          const startTimeInADate = dayjs()
            .startOf("day")
            .set("hour", splitedstartTime[0])
            .set("minute", splitedstartTime[1]);
          const maxTime = dayjs()
            .startOf("date")
            .set("hour", maxPosibleTimeSplited[0])
            .set("minutes", maxPosibleTimeSplited[1]);
          return (
            this.modeTimeLapse ||
            !this.showTimersField ||
            startTimeInADate <= maxTime
          );
        },
      },
      end_time: {
        checkMinTime(val) {
          const splitedEndTime = val.split(":");
          const minPosibleTimeSplited = this.minClassTime.split(":");
          const endTimeInADate = dayjs()
            .startOf("date")
            .set(
              "hour",
              splitedEndTime[0] != ""
                ? splitedEndTime[0]
                : minPosibleTimeSplited[0]
            )
            .set(
              "minute",
              splitedEndTime[1] ? splitedEndTime[1] : minPosibleTimeSplited[1]
            );
          const minTime = dayjs()
            .startOf("date")
            .set("hour", minPosibleTimeSplited[0])
            .set("minute", minPosibleTimeSplited[1]);
          return (
            this.modeTimeLapse ||
            !this.showTimersField ||
            endTimeInADate >= minTime ||
            this.secondStep.from_date == this.minClassTime
          );
        },
        checkMaxTime(val) {
          const splitedEndTime = val.split(":");
          const minEndTimeSplited = this.minClassTime.split(":");
          const maxPosibleTimeSplited = this.maxClassTime.split(":");
          const endTimeInADate = dayjs()
            .startOf("date")
            .set(
              "hour",
              splitedEndTime[0] != "" ? splitedEndTime[0] : minEndTimeSplited[0]
            )
            .set(
              "minute",
              splitedEndTime[1] ? splitedEndTime[1] : minEndTimeSplited[1]
            );
          const maxTime = dayjs()
            .startOf("date")
            .set("hour", maxPosibleTimeSplited[0])
            .set("minute", maxPosibleTimeSplited[1]);
          return (
            this.modeTimeLapse ||
            !this.showTimersField ||
            endTimeInADate <= maxTime
          );
        },
        checkstartTime(val) {
          const splitedEndTime = val.split(":");
          const splitedstartTime = this.secondStep.start_time.split(":");
          const endTimeInADate = dayjs()
            .startOf("day")
            .set("hour", Number(splitedEndTime[0]))
            .set("minute", Number(splitedEndTime[1]));
          const startTimeInADate = dayjs()
            .startOf("day")
            .set("hour", splitedstartTime[0])
            .set("minute", splitedstartTime[1]);
          return (
            this.modeTimeLapse ||
            !this.showTimersField ||
            endTimeInADate.diff(startTimeInADate, "hour", true) >= 0
          );
        },
      },
    },
  },
  computed: {
    startDateErrors() {
      const errors = [];
      if (!this.$v.secondStep.from_date.$dirty) return errors;
      if (!this.$v.secondStep.from_date.maxDate)
        errors.push("La fecha de inicio no puede superar al día de hoy");
      return errors;
    },
    endDateErrors() {
      const errors = [];
      if (!this.$v.secondStep.to_date.$dirty) return errors;
      if (!this.$v.secondStep.to_date.checkDate)
        errors.push(
          "La fecha de fin debe de estar después de la fecha de inicio"
        );
      if (!this.$v.secondStep.to_date.maxDate)
        errors.push("La fecha de fin no puede superar al día de hoy");
      return errors;
    },
    startTimeErrors() {
      const errors = [];

      if (!this.$v.secondStep.from_date.$dirty) return errors;
      if (
        !this.$v.secondStep.start_time.checkMinTime &&
        this.secondStep.start_time != ""
      )
        errors.push(
          `La hora de inicio debe de ser mayor a las ${this.formatTime(
            this.minClassTime
          )}`
        );
      if (
        !this.$v.secondStep.start_time.checkMaxTime &&
        this.secondStep.start_time != ""
      )
        errors.push(
          `"La hora de inicio debe de ser menor a las ${this.formatTime(
            this.maxClassTime
          )}`
        );
      if (this.secondStep.start_time == "") {
        errors.push("Debes de seleccionar una hora");
      }
      return errors;
    },
    endTimeErrors() {
      const errors = [];

      if (!this.$v.secondStep.end_time.$dirty) return errors;
      if (
        !this.$v.secondStep.end_time.checkstartTime &&
        this.secondStep.end_time != ""
      ) {
        errors.push("La hora de fin no puede de ser menor a la hora de inicio");
      }
      if (
        !this.$v.secondStep.end_time.checkMinTime &&
        this.secondStep.end_time != ""
      ) {
        errors.push(
          `La hora de fin debe de ser mayor a las ${this.formatTime(
            this.minClassTime
          )}`
        );
      }
      if (
        !this.$v.secondStep.end_time.checkMaxTime &&
        this.secondStep.end_time != ""
      ) {
        errors.push(
          `La hora de fin debe de ser menor a las ${this.formatTime(
            this.maxClassTime
          )}`
        );
      }
      if (this.secondStep.end_time == "") {
        errors.push("Debes de seleccionar una fecha");
      }
      return errors;
    },
  },
};
</script>